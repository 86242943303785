import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import favicon from '../../static/favicon.png';
import { window } from 'browser-monads';

const url = window.location;

const SEO = props => (
	<StaticQuery
		query={detailsQuery}
		render={data => {
			const title = props.title || data.site.siteMetadata.title;
			return (
				<Helmet
					htmlAttributes={{
						lang: 'en'
					}}
					title={title}
					titleTemplate={`%s | ${data.site.siteMetadata.title}`}
					link={[
						{ rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
					]}
					meta={[
						{ property: 'og:type', content: 'website' },
						{
							property: 'og:title',
							content: `${data.site.siteMetadata.title}`
						},
						{ property: 'og:url', content: `${url}` }
					]}
				/>
			);
		}}
	/>
);

SEO.defaultProps = {
	lang: 'en',
	meta: [],
	keywords: []
};

export default SEO;

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
