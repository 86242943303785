import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { getCurrentLangKey } from 'ptz-i18n';
import { window } from 'browser-monads';

const MenuMobileSv = props => {
	const url = window.location.pathname;
	const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;
	const langKey = getCurrentLangKey(langs, defaultLangKey, url);
	return (
		<div
			id="main-menu-mobile"
			className={`main-menu-mobile ${props.active ? 'open' : ''}`}
		>
			<ul>
				<li>
					<Link to={`${langKey}/`}>START</Link>
				</li>
				<li>
					<Link to={`${langKey}/about/`}>OM</Link>
				</li>
				<li>
					<Link to={`${langKey}/contact/`}>KONTAKTA</Link>
				</li>
				<li>|</li>
				{langs.map(lang => (
					<li key={lang}>
						<Link to={`${lang}/`}>
							{lang == 'sv' ? '🇸🇪' : lang == 'it' ? '🇮🇹' : '🇬🇧'}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query MenuMobileQuerySv {
				site {
					siteMetadata {
						languages {
							defaultLangKey
							langs
						}
					}
				}
				allMenuSvJson {
					edges {
						node {
							id
							name
							link
						}
					}
				}
			}
		`}
		render={data => <MenuMobileSv active={props.active} data={data} />}
	/>
);
