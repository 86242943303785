import React from 'react'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CookieConsent from "react-cookie-consent";
import '../scss/style.scss'

const Layout = props => {
  return (
    <React.Fragment>
      <SEO />
      <div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ''}`}>
        <div id="wrapper" className="wrapper">
          <Header />
          {props.children}
        </div>
        <Footer />
        <CookieConsent
          buttonText="Got it!"
          declineButtonText="Opt out"
          enableDeclineButton
          onDecline={() => {window['ga-disable-UA-137573670-1'] = true;}}
        >
          This website uses cookies to ensure you get the best experience on our website. <a style={{ color: "#fff", textDecoration: "underline" }} href="https://www.cookiesandyou.com" rel="nofollow" target="_blank">Learn more</a>
        </CookieConsent>
      </div>
    </React.Fragment>
  )
}

export default Layout

const guid = graphql`
  query guid {
    site {
      siteMetadata {
        guid
      }
    }
  }
`;
