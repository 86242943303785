import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import MenuEn from './MenuEn';
import MenuSv from './MenuSv';
import Hamburger from './Hamburger';
import MenuMobileEn from './MenuMobileEn';
import MenuMobileSv from './MenuMobileSv';
import { getCurrentLangKey } from 'ptz-i18n';
import { window } from 'browser-monads';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuActive: false
		};
	}

	toggleMenu = menuActive => {
		this.setState(prevState => ({
			menuActive: !prevState.menuActive
		}));
	};

	render() {
		const url = window.location.pathname;
		const {
			langs,
			defaultLangKey
		} = this.props.data.site.siteMetadata.languages;
		const siteTitle = this.props.data.site.siteMetadata.title;
		const langKey = getCurrentLangKey(langs, defaultLangKey, url);
		return (
			<div className="header">
				<div className="container">
					<div className="logo">
						<Link to={`/${langKey}/`}>{siteTitle}</Link>
					</div>
					<div className="logo-mobile">
						<Link to={`/${langKey}/`}>{siteTitle}</Link>
					</div>
					{langKey == 'en' ? (
						<MenuMobileEn active={this.state.menuActive} />
					) : (
						<MenuMobileSv active={this.state.menuActive} />
					)}
					{langKey == 'en' ? <MenuEn /> : <MenuSv />}
					<Hamburger toggleMenu={this.toggleMenu} />
				</div>
			</div>
		);
	}
}

export default props => (
	<StaticQuery
		query={graphql`
			query SiteHeaderQuery {
				site {
					siteMetadata {
						title
						languages {
							defaultLangKey
							langs
						}
					}
				}
			}
		`}
		render={data => <Header data={data} />}
	/>
);
