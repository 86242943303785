import React, { useState } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { getCurrentLangKey } from 'ptz-i18n';
import { window } from 'browser-monads';

const Menu = props => {
	const url = window.location.pathname;
	const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;
	const langKey = getCurrentLangKey(langs, defaultLangKey, url);
	const [serviceMenu, setserviceMenu] = useState(false);
	const serviceItems = props.data.allPrismicServices.edges;
	function menuToggle() {
		serviceMenu ? setserviceMenu(false) : setserviceMenu(true);
	}

	return (
		<div id="main-menu" className="main-menu">
			<ul>
				<li>
					<Link to={`${langKey}/`}>HOME</Link>
				</li>
				<li>
					<Link to={`${langKey}/about/`}>ABOUT</Link>
				</li>
				<li
					className={`nav-item dropdown ${serviceMenu ? `show` : null}`}
					onClick={menuToggle}
				>
					<a
						className="nav-link dropdown-toggle"
						href="#"
						id="navbarDropdown"
						role="button"
					>
						SERVICES
					</a>
					<div
						className={`dropdown-menu ${serviceMenu ? `show` : null}`}
						aria-labelledby="navbarDropdown"
					>
						{serviceItems.map(item => (
							<Link
								key={item.node.id}
								className="dropdown-item"
								to={`${langKey}/services/${item.node.uid}`}
							>
								{item.node.data.service_name.text}
							</Link>
						))}
					</div>
				</li>
				<li>
					<Link to={`${langKey}/contact/`}>CONTACT</Link>
				</li>
				<li>|</li>
				{langs.map(lang => (
					<li key={lang}>
						<Link to={`${lang}/`}>
							{lang == 'sv' ? '🇸🇪' : lang == 'it' ? '🇮🇹' : '🇬🇧'}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query SiteTitleQueryEn {
				site {
					siteMetadata {
						languages {
							defaultLangKey
							langs
						}
					}
				}
				allPrismicServices(filter: { lang: { eq: "en-gb" } }) {
					edges {
						node {
							id
							uid
							data {
								service_name {
									text
								}
							}
						}
					}
				}
			}
		`}
		render={data => <Menu data={data} />}
	/>
);
